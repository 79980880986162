<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.redirects">
			<template #title>Redirects</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'RedirectsNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>
			<v-data-table :headers="headers" :items="redirects" item-key="id" :search="search">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-btn icon @click="editPage(item.id)">
						<v-icon>{{$icons.edit}}</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "RedirectsIndex",
	computed:{
		redirects: sync('redirects/redirects')
	},
	data: () => ({
		search: '',
		headers:[
			{
				text: 'From',
				align: 'start',
				sortable: true,
				value: 'redirect_from',
			},
			{
				text: 'To',
				align: 'start',
				sortable: true,
				value: 'redirect_to',
			},
			{
				text: '',
				align: 'end',
				sortable: false,
				value: 'actions',
			},
		]
	}),
	beforeMount(){
		this.init()
	},
	methods:{
		init(){
			this.getRedirects()
		},
		editPage(id){
			this.$router.push({name:'RedirectsEdit', params: {id}})
		},
		getRedirects(){
			this.$api.redirects.index()
				.then(response => {
					this.redirects = response.data
				})
				.catch(error => {
					console.log(error);
					this.$toast.add(error.message, 'error')
				})
		},
		filterOnlyCapsText (value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().toLocaleUpperCase().indexOf(search) !== -1
		},
	}
}
</script>